<template>
  <div class="volunteer">
      <div class="volunteer-continar">
     <Tablist></Tablist>
  </div>
  </div>
</template>

<script>
import Tablist from './components/tablist'
export default {
  components: { 
      Tablist
   },
  data () {
    return {
      
    }
  },
  created () {
  },
  methods: {
   
  },
  name: 'Volunteer'
}
</script>

<style lang="less" scoped>
.volunteer {
    background-color: #f5f5f5;
    .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}
</style>
